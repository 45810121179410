import {createTheme, ThemeOptions} from '@mui/material'

import {componentsOverrides} from './componentsOverrides'
import {
  darkModeShadows,
  hpDarkPalette,
  hpLightPalette,
  lightModeShadows,
  typographyTokens,
  shapeTokens
} from './designTokens'

// Light theme
// exporting for use in HConnectTheme
export const hpLightThemeOptions: ThemeOptions = {
  palette: hpLightPalette,
  shadows: lightModeShadows,
  shape: shapeTokens,
  typography: typographyTokens,
  components: componentsOverrides
}

export const hpTheme = createTheme(hpLightThemeOptions)

// Dark theme
const darkThemeOptions: ThemeOptions = {
  palette: hpDarkPalette,
  shadows: darkModeShadows,
  shape: shapeTokens,
  typography: typographyTokens,
  components: componentsOverrides
}
export const hpDarkTheme = createTheme(darkThemeOptions)

import {IconButton, IconButtonProps, SxProps, Theme} from '@mui/material'
import {isFunction} from 'lodash'
import React, {Children, cloneElement, forwardRef, isValidElement, Attributes} from 'react'

import {useIsMobile} from '../hooks'

type TransparentButtonProps = Omit<IconButtonProps, 'sx'> & {
  sx?: Omit<SxProps<Theme>, 'color' | 'borderColor' | 'border' | 'p'>
}

export const ShellIconButton = forwardRef<HTMLElement, TransparentButtonProps>(
  // removing the unused ref causes a React warning (very weird)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({children, sx, ...props}, ref) => {
    const isMobile = useIsMobile()

    return (
      <IconButton
        sx={(theme) => {
          const {palette, shadows} = theme
          const isDarkMode = palette.mode === 'dark'
          return {
            border: 1,
            borderColor: palette.grey[200],
            boxShadow: shadows[2],
            p: '11px',
            color: palette.text.primary,
            '&:hover': isMobile
              ? {
                  backgroundColor: isDarkMode ? palette.grey[700] : palette.grey[200],
                  boxShadow: 'none'
                }
              : {
                  backgroundColor: isDarkMode ? palette.grey[800] : palette.grey[100]
                },
            '&:active': {
              backgroundColor: isDarkMode ? palette.grey[700] : palette.grey[200],
              boxShadow: 'none'
            },
            ...(isFunction(sx) ? sx(theme) : sx)
          }
        }}
        {...props}
      >
        {Children.map(children, (child) => {
          return (
            isValidElement(child) &&
            cloneElement(child, {
              style: {fontSize: '16px'}
            } as Attributes)
          )
        })}
      </IconButton>
    )
  }
)

import React, {createContext, useContext, useState, ReactNode, useCallback, useMemo} from 'react'

import {ThemeMode} from './themeSettings.types'

interface ThemeContextProps {
  themeMode: ThemeMode
  setThemeMode: (theme: ThemeMode) => void
}

const ThemeSettingsContext = createContext<ThemeContextProps | undefined>(undefined)

interface ThemeSettingsProviderProps {
  children: ReactNode
  // optional initial theme mode and callback for when theme mode changes (e.g. to save in local storage)
  initialThemeMode?: ThemeMode
  onThemeModeChange?: (themeMode: ThemeMode) => void
}

export const ThemeSettingsProvider: React.FC<ThemeSettingsProviderProps> = ({
  children,
  initialThemeMode,
  onThemeModeChange
}) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(initialThemeMode ?? ThemeMode.Device)

  const updateThemeMode = useCallback(
    (themeMode: ThemeMode) => {
      setThemeMode(themeMode)
      onThemeModeChange?.(themeMode)
    },
    [setThemeMode, onThemeModeChange]
  )

  const memoizedContextState = useMemo(
    () => ({themeMode, setThemeMode: updateThemeMode}),
    [themeMode, updateThemeMode]
  )

  return (
    <ThemeSettingsContext.Provider value={memoizedContextState}>
      {children}
    </ThemeSettingsContext.Provider>
  )
}

export const useThemeSettings = (): ThemeContextProps => {
  const context = useContext(ThemeSettingsContext)
  if (!context) {
    throw new Error('BUG: useThemeSettings must be used within a ThemeSettingsContext')
  }
  return context
}

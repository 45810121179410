import {List} from '@mui/material'
import React from 'react'

import {dataTestId as spreadDataTestId} from '../../../../../common/utils/testingUtils'
import {DropdownMenuButtonLink} from '../../../DropdownMenuButtonLink'

export interface DropdownLink {
  title: string
  onClick: () => void
  startIcon: React.ReactNode
  endIcon?: React.ReactNode
  dataTestId?: string
  isSelected?: boolean
}

interface DropdownLinksProps {
  header?: React.ReactNode
  links: DropdownLink[]
  footer?: React.ReactNode
  dataTestId?: string
}

export const DropdownLinks: React.FC<DropdownLinksProps> = ({
  header,
  footer,
  links,
  dataTestId
}) => {
  return (
    <List
      disablePadding
      subheader={header}
      sx={{backgroundColor: 'background.paper'}}
      {...spreadDataTestId(dataTestId ?? 'dropdown_links')}
    >
      {links.map(({startIcon, endIcon, title, onClick, dataTestId, isSelected}, index) => (
        <DropdownMenuButtonLink
          key={index}
          startIcon={startIcon}
          endIcon={endIcon}
          content={title}
          onClick={onClick}
          dataTestId={dataTestId}
          isSelected={isSelected}
        />
      ))}
      {footer}
    </List>
  )
}

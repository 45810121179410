import {PersonOutline} from '@mui/icons-material'
import {ListSubheader, Box, Link, Popover, Typography} from '@mui/material'
import queryString from 'query-string'
import React, {SyntheticEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {GuidedTour} from '../../../components/guidedTour/types'
import {GuidedTourStatus} from '../../../components/guidedTour/types/GuidedTour.enums'
import {ThemeMode} from '../../../theme'
import {Locales} from '../../LanguageSwitcher'
import {ShellIconButton} from '../../ShellIconButton'

import {DropdownDetails} from './components/DropdownDetails'
import {DropdownFooter, DropdownFooterProps} from './components/DropdownFooter'
import {DropdownLinks} from './components/DropdownLinks'
import {DropdownNotification} from './components/DropDownNotification'
import type {LinkDetail} from './hooks/dropdownLinks.types'
import {useDropdownLinks} from './hooks/useDropdownLinks'

export interface Content {
  header?: string | null
  body: React.ReactNode
}

interface Profile {
  name: string
  email: string
}

interface Action {
  logout?: () => void
  selectLanguage?: (language: string) => void
  imprint?: () => void
  osSoftwareAttribution?: () => void
  signIn?: () => void
  tc?: () => void
  dpp?: () => void
  ac?: () => void
}

interface HProduceAccountDropdownProps {
  actions: Action
  logoutButtonText?: string
  imprintText?: string
  osSoftwareAttributionText?: string
  signInText?: string
  tcText?: string
  dppText?: string
  acText?: string
  profile?: Profile
  content?: Content
  locales?: Locales
  userPage?: string
  guidedTours?: {tours: Record<string, GuidedTour>; statuses: Record<string, GuidedTourStatus>}
  enableMyTasks?: boolean
  closeDropdownOnLanguageSwitch?: boolean
  footer?: DropdownFooterProps
  themeMode?: ThemeMode
}

export const HProduceAccountDropdown: React.FC<HProduceAccountDropdownProps> = ({
  actions,
  logoutButtonText,
  imprintText,
  osSoftwareAttributionText,
  signInText,
  tcText,
  dppText,
  acText,
  profile,
  footer,
  content,
  locales,
  userPage,
  enableMyTasks = false,
  guidedTours,
  closeDropdownOnLanguageSwitch = false,
  themeMode
}) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()
  const [linkDetailSection, setLinkDetailsSection] = useState<LinkDetail>()
  const isCarbonBank = queryString
    .parse(window.location.search)
    .ids_return_url?.includes('CarbonBank')
  const handleClick = (event: SyntheticEvent<EventTarget>) =>
    setAnchorEl(event.currentTarget as HTMLInputElement)

  const handleClose = () => {
    setLinkDetailsSection(undefined)
    setAnchorEl(null)
  }

  const newGuidedTours =
    guidedTours &&
    Object.entries(guidedTours.tours).filter(
      ([tourKey]) => guidedTours.statuses[tourKey] === GuidedTourStatus.NEW
    )
  const isNewGuidedToursAvailable = newGuidedTours ? newGuidedTours.length > 0 : false

  const dropdownLinks = useDropdownLinks({
    guidedTours,
    onCloseDropdown: handleClose,
    setLinkDetailsSection,
    logoutButtonText,
    imprintText,
    osSoftwareAttributionText,
    signInText,
    tcText,
    dppText,
    acText,
    logout: actions.logout,
    imprint: actions.imprint,
    osSoftwareAttribution: actions.osSoftwareAttribution,
    signIn: actions.signIn,
    tc: actions.tc,
    dpp: actions.dpp,
    ac: actions.ac,
    locales,
    selectLanguage: actions.selectLanguage,
    shouldEnableMyTasks: enableMyTasks,
    closeDropdownOnLanguageSwitch,
    themeMode
  })

  const shouldShowNotification = isNewGuidedToursAvailable
  return (
    <Box sx={{position: 'relative'}}>
      {shouldShowNotification && <DropdownNotification />}
      <ShellIconButton
        onClick={(e) => handleClick(e)}
        data-test-id="account-menu-icon"
        aria-label="show account menu"
        sx={({palette}) => ({
          ...(anchorEl
            ? {boxShadow: 'none'}
            : isCarbonBank
              ? {
                  backgroundColor: palette.common.white,
                  border: '1px solid rgba(230, 234, 237, 1)'
                }
              : {})
        })}
        id="account-menu"
      >
        <PersonOutline />
      </ShellIconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPopover-paper': {
            mt: 2.5,
            width: 360,
            maxWidth: 360
          }
        }}
        data-test-id="account-menu-popover"
      >
        {linkDetailSection && (
          <DropdownDetails
            header={{
              title: linkDetailSection.title,
              icon: linkDetailSection.icon,
              onHeaderClick: linkDetailSection.onGoBack
            }}
          >
            {linkDetailSection.content}
          </DropdownDetails>
        )}
        {!linkDetailSection && (
          <DropdownLinks
            header={
              // User info section
              (profile || content) && (
                <ListSubheader component="div" sx={{p: 2}}>
                  {profile && (
                    <>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        data-test-id="account-menu-profile-name"
                      >
                        {profile.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        data-test-id="account-menu-profile-email"
                        sx={{overflowWrap: 'break-word'}}
                      >
                        {profile.email}
                      </Typography>
                      {userPage && (
                        <Link href={userPage}>
                          {t('accountDropdown.goToUserPage', 'Go to user page')}
                        </Link>
                      )}
                    </>
                  )}
                  {content && (
                    <Box p={2}>
                      {content.header && (
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          data-test-id="account-menu-profile-accounts"
                        >
                          {content.header}
                        </Typography>
                      )}
                      {content.body}
                    </Box>
                  )}
                </ListSubheader>
              )
            }
            links={dropdownLinks}
          />
        )}
        {footer && <DropdownFooter footer={footer} />}
      </Popover>
    </Box>
  )
}

import {
  LightModeOutlined,
  DarkModeOutlined,
  SettingsBrightnessOutlined,
  NavigateNext,
  PaletteOutlined
} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'

import {ThemeMode} from '../../../../theme'
import {DropdownLink} from '../components/DropdownLinks'
import {LinkDetail} from '../hooks/dropdownLinks.types'

import {ThemeModeDropdownContent} from './ThemeModeDropdownContent'

const themeModeIconMap = {
  [ThemeMode.Light]: <LightModeOutlined color="secondary" />,
  [ThemeMode.Dark]: <DarkModeOutlined color="secondary" />,
  [ThemeMode.Device]: <SettingsBrightnessOutlined color="secondary" />
}

interface UseThemeModeDropdownLinkParams {
  themeMode: ThemeMode | undefined
  setLinkDetailsSection: (detail: LinkDetail | undefined) => void
}

export const useThemeModeDropdownLink = ({
  themeMode,
  setLinkDetailsSection
}: UseThemeModeDropdownLinkParams): DropdownLink | undefined => {
  const {t} = useTranslation()

  if (!themeMode) {
    return undefined
  }

  return {
    title: t(`themeModeSettings.${themeMode}`),
    startIcon: themeModeIconMap[themeMode],
    endIcon: <NavigateNext color="secondary" data-test-id={'select_theme_mode_btn'} />,
    onClick: () =>
      setLinkDetailsSection({
        title: 'Theme',
        icon: <PaletteOutlined color="secondary" />,
        onGoBack: () => setLinkDetailsSection(undefined),
        content: <ThemeModeDropdownContent />
      }),
    dataTestId: 'theme_select_btn'
  }
}

import {getLanguageTitle} from '@hconnect/common/utils/language.util'
import CheckIcon from '@mui/icons-material/Check'
import {Theme, useTheme} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {flags} from '../../common/flags'

import {DropdownLinks} from './header/accountDropdown/components/DropdownLinks'

export interface Locale {
  code: string
  name: string
}

export interface Locales {
  defaultLocale: string
  locales: Locale[] | null
}

interface LanguageSwitcherProps {
  locales: Locales
  onSelectLanguage: (language: string) => void
}

const flagStyle = ({spacing}: Theme): React.CSSProperties => ({
  width: spacing(2),
  height: spacing(2)
})

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({locales, onSelectLanguage}) => {
  const theme = useTheme()
  const [selectedLanguage, setSelectedLanguage] = useState<string>(locales.defaultLocale)
  const handleLanguageChange = (code: string) => {
    onSelectLanguage(code)
    setSelectedLanguage(code)
  }

  const {t} = useTranslation()

  const selectableLocales = useMemo(() => {
    if (!locales?.locales) {
      return []
    }
    return locales.locales
  }, [locales])

  if (selectableLocales.length <= 1) {
    return null
  }

  const decodeLocale = (locale: string) => {
    return locale.split('-') || []
  }

  return (
    <DropdownLinks
      links={selectableLocales?.map((locale: Locale, index) => {
        const [language, country] = decodeLocale(locale.code)
        return {
          startIcon: (
            <img style={flagStyle(theme)} src={flags[locale.code] as string} alt={locale.name} />
          ),
          isSelected: locale.code === selectedLanguage,
          title: getLanguageTitle(language, country) || t(`locales.${locale.code}`),
          onClick: () => handleLanguageChange(locale.code),
          dataTestId: `language-switcher1-${locale.code}`,
          index,
          endIcon: locale.code === selectedLanguage ? <CheckIcon color={'primary'} /> : undefined
        }
      })}
    />
  )
}

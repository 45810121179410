import {PaletteOptions, alpha, Color} from '@mui/material'
import {CommonColors} from '@mui/material/styles/createPalette'
import {TypographyOptions} from '@mui/material/styles/createTypography'
import {Shadows} from '@mui/material/styles/shadows'
import {ShapeOptions} from '@mui/system'

const common: CommonColors = {
  black: '#000000',
  white: '#ffffff'
}

const grey = {
  50: '#fbfbfc', // grey-99
  100: '#f5f6f7', // grey-97
  200: '#eaedf0', // grey-94
  300: '#d4dbe0', // grey-88
  400: '#bdc4c9',
  500: '#9ea5aa',
  600: '#54708c', // grey-55
  700: '#435a70', // grey-44
  800: '#324354', // grey-33
  900: '#171f26', // grey-15
  A100: '#f5f6f7',
  A200: '#eaedf0',
  A400: '#bdc4c9',
  A700: '#435a70'
} as Color

export const hpLightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#0162c4',
    light: '#016ad4',
    dark: '#0158b0',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#54708c',
    light: '#d4dbe0',
    dark: '#00274d',
    contrastText: '#ffffff'
  },
  error: {
    main: '#cc0801',
    light: '#da0901',
    dark: '#bf0801',
    contrastText: '#ffffff'
  },
  warning: {
    main: '#d67d00',
    light: '#ee8b00',
    dark: '#be6f00',
    contrastText: '#ffffff'
  },
  success: {
    main: '#009a48',
    light: '#00ab50',
    dark: '#008940',
    contrastText: '#ffffff'
  },
  info: {
    main: '#016ad4', // info
    light: '#80b4e9', // info-faded-30
    dark: '#0155aa', // info-selected
    contrastText: '#ffffff'
  },
  grey,
  text: {
    primary: '#00274d',
    secondary: '#54708c',
    disabled: '#00274d99'
  },
  common,
  divider: '#eaedf0',
  background: {
    paper: '#ffffff',
    default: '#00274d' // TODO consider change
  }
}

export const hpDarkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#3488dd', // primary
    light: '#80b4e9', // primary-selected
    dark: '#014a94', // primary-faded-30
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#94a6b8', // neutral
    light: '#b2bfcc', // neutral-selected
    dark: '#475a6b', // neutral-faded-30
    contrastText: '#ffffff'
  },
  error: {
    main: '#e5534d', // negative
    light: '#ed8480', // negative-selected
    dark: '#990601', // negative-faded-30
    contrastText: '#ffffff'
  },
  warning: {
    main: '#f3ae4d', // warning
    light: '#f7c580', // warning-selected
    dark: '#a76100', // warning-faded-30
    contrastText: '#ffffff'
  },
  success: {
    main: '#4dc484', // positive
    light: '#80d5a7', // positive-selected
    dark: '#007838', // positive-faded-30
    contrastText: '#ffffff'
  },
  info: {
    main: '#3488dd', // info
    light: '#80b4e9', // info-selected
    dark: '#014a94', // info-faded-30
    contrastText: '#ffffff'
  },
  grey,
  text: {
    primary: '#ffffff', // foreground
    secondary: '#d4dbe0',
    disabled: '#EEDEFA'
  },
  common,
  divider: '#242d36', // border
  background: {
    paper: '#1a2026', // container background
    default: '#000000' // page background
  }
}

// elevation levels from the Figma design system, mapped to MUI elevation levels 1, 8 and 16 respectively

const elevation1 = (color1: string) => `0px 0px 6px ${color1}`
const elevation2 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 1px 2px ${color2}`
const elevation3 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 1px 4px ${color2}`
const elevation4 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 2px 5px ${color2}`
const elevation5 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 2px 7px ${color2}`
const elevation6 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 3px 8px ${color2}`
const elevation7 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 3px 10px ${color2}`
const elevation8 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 4px 12px ${color2}`
const elevation9 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 4px 14px ${color2}`
const elevation10 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 5px 15px ${color2}`
const elevation11 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 5px 17px ${color2}`
const elevation12 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 6px 18px ${color2}`
const elevation13 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 6px 20px ${color2}`
const elevation14 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 7px 21px ${color2}`
const elevation15 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 7px 23px ${color2}`
const elevation16 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 8px 24px ${color2}`
const elevation17 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 9px 27px ${color2}`
const elevation18 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 10px 30px ${color2}`
const elevation19 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 11px 33px ${color2}`
const elevation20 = (color1: string, color2: string) =>
  `0px 0px 1px ${color1}, 0px 12px 36px ${color2}`
const elevation21 = (color1: string, color2: string) =>
  `0px 0px 2px ${color1}, 0px 13px 39px ${color2}`
const elevation22 = (color1: string, color2: string) =>
  `0px 0px 2px ${color1}, 0px 14px 42px ${color2}`
const elevation23 = (color1: string, color2: string) =>
  `0px 0px 2px ${color1}, 0px 15px 45px ${color2}`
const elevation24 = (color1: string, color2: string) =>
  `0px 0px 2px ${color1}, 0px 16px 48px ${color2}`

const lightColor1 = alpha(common.black, 0.1)
const lightColor2 = alpha(common.black, 0.12)
const lightColorDefault = alpha(common.black, 0.05)
const lightColor3 = alpha(common.black, 0.16)

const darkColor1 = alpha(common.white, 0.1)
const darkColor2 = alpha(common.white, 0.12)
const darkColorDefault = alpha(common.white, 0.05)
const darkColor3 = alpha(common.white, 0.16)

export const lightModeShadows: Shadows = [
  // 0-4
  'none',
  elevation1(lightColorDefault), // 1 is the paper's default elevation
  elevation2(lightColor1, lightColor2),
  elevation3(lightColor1, lightColor2),
  elevation4(lightColor1, lightColor2),
  // 5-9
  elevation5(lightColor1, lightColor2),
  elevation6(lightColor1, lightColor2),
  elevation7(lightColor1, lightColor2),
  elevation8(lightColor1, lightColor2), // 8 is the popover's default elevation
  elevation9(lightColor1, lightColor2),
  // 10-14
  elevation10(lightColor1, lightColor2),
  elevation11(lightColor1, lightColor2),
  elevation12(lightColor1, lightColor2),
  elevation13(lightColor1, lightColor2),
  elevation14(lightColor1, lightColor2),
  // 15-19
  elevation15(lightColor1, lightColor2),
  elevation16(lightColor1, lightColor3), // 16 is the drawer's default elevation
  elevation17(lightColor1, lightColor3),
  elevation18(lightColor1, lightColor3),
  elevation19(lightColor1, lightColor3),
  // 20-24
  elevation20(lightColor1, lightColor3),
  elevation21(lightColor1, lightColor3),
  elevation22(lightColor1, lightColor3),
  elevation23(lightColor1, lightColor3),
  elevation24(lightColor1, lightColor3)
]

export const darkModeShadows: Shadows = [
  // 0-4
  'none',
  elevation1(darkColorDefault), // 1 is the paper's default elevation
  elevation2(darkColor1, darkColor2),
  elevation3(darkColor1, darkColor2),
  elevation4(darkColor1, darkColor2),
  // 5-9
  elevation5(darkColor1, darkColor2),
  elevation6(darkColor1, darkColor2),
  elevation7(darkColor1, darkColor2),
  elevation8(darkColor1, darkColor2), // 8 is the popover's default elevation
  elevation9(darkColor1, darkColor2),
  // 10-14
  elevation10(darkColor1, darkColor2),
  elevation11(darkColor1, darkColor2),
  elevation12(darkColor1, darkColor2),
  elevation13(darkColor1, darkColor2),
  elevation14(darkColor1, darkColor2),
  // 15-19
  elevation15(darkColor1, darkColor2),
  elevation16(darkColor1, darkColor3), // 16 is the drawer's default elevation
  elevation17(darkColor1, darkColor3),
  elevation18(darkColor1, darkColor3),
  elevation19(darkColor1, darkColor3),
  // 20-24
  elevation20(darkColor1, darkColor3),
  elevation21(darkColor1, darkColor3),
  elevation22(darkColor1, darkColor3),
  elevation23(darkColor1, darkColor3),
  elevation24(darkColor1, darkColor3)
]

const hpAdditionalLightModeColors = {
  backgroundGradient: 'radial-gradient(70.49% 70.49% at 50% 10.41%, #18699f 0%, #0f4b73 100%)',
  primaryBackgroundColor: '#0162c40a',
  lightChart1: '#8C3CC9',
  contentSelected: 'rgba(1, 106, 212, 0.08)',
  contentHover: 'rgba(1, 106, 212, 0.04)',
  contentSelectedHover: 'rgba(1, 106, 212, 0.12)',
  // success color with 0.15 opacity
  successBackgroundColor: '#d5efe2',
  errorBackgroundColor: '#cc08010a',
  disabledBackgroundColor: '#00274d0a',
  buttonFillColor: '#edeff0',
  mediumInputBorder: 'rgba(255, 255, 255, 0.4)',
  mediumButtonBorder: 'rgba(255, 255, 255, 0.15)',
  mediumButtonBackground: 'rgba(255, 255, 255, 0.05)',
  mediumButtonBackgroundHover: 'rgba(255, 255, 255, 0.03)',
  mediumButtonBackgroundDown: 'rgba(255, 255, 255, 0.05)',
  // color accents
  accent1: '#8F26E0',
  accent1faded20: '#EEDEFA',
  accent1faded30: '#C792EF',
  accent2: '#009D93',
  accent2faded20: '#D9F0EF',
  accent2faded30: '#80CEC9',
  accent3: '#CB2BBC',
  accent3faded30: '#DB6BD0',
  accent4: '#98C31C',
  accent4faded30: '#CBE18D',
  accent5: '#EE6400',
  accent5faded30: '#F7B180',
  semanticInfoFaded20: '#D9E9F9',
  semanticInfoFaded30: '#80B4E9',
  neutral: '#668099',
  neutralFaded20: '#E8ECF0'
}

export const customThemeConstants = () => ({
  palette: {...hpAdditionalLightModeColors},
  labelOpacity: 0.6,
  labelOpacityHover: 0.8,
  defaultGridSpacing: {xs: 2, md: 3}
})

export const typographyTokens: TypographyOptions = {
  fontFamily: ['"Inter"', 'sans-serif'].join(','),
  h1: {
    // page title
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '34px'
  },
  h2: {
    // section title
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  h3: {
    // card title
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  h4: {
    // card section
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px'
  },
  h5: {
    // card section
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px'
  },
  h6: {
    // card section
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px'
  },
  subtitle1: {
    // in the Figma design docs referred to as Value
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px'
  },
  subtitle2: {
    // in the Figma design docs referred to as Label Highlight
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px'
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  button: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    textTransform: 'none'
  }
}

export const shapeTokens: ShapeOptions = {
  borderRadius: 8
}

import {
  LightModeOutlined,
  DarkModeOutlined,
  SettingsBrightnessOutlined,
  Check
} from '@mui/icons-material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ThemeMode, useThemeSettings} from '../../../../theme'
import {DropdownLinks} from '../components/DropdownLinks'

const themeModeIconMap = {
  [ThemeMode.Light]: <LightModeOutlined color="secondary" />,
  [ThemeMode.Dark]: <DarkModeOutlined color="secondary" />,
  [ThemeMode.Device]: <SettingsBrightnessOutlined color="secondary" />
}

export const ThemeModeDropdownContent: React.FC = () => {
  const {themeMode, setThemeMode} = useThemeSettings()
  const [selectedThemeMode, setSelectedThemeMode] = useState<ThemeMode>(themeMode)
  const {t} = useTranslation()

  const handleThemeModeChange = (mode: ThemeMode) => {
    setSelectedThemeMode(mode)
    setThemeMode(mode)
  }

  return (
    <DropdownLinks
      dataTestId="theme_mode_dropdown_content"
      links={Object.values(ThemeMode).map((mode) => ({
        startIcon: themeModeIconMap[mode],
        endIcon: selectedThemeMode === mode ? <Check color={'primary'} /> : undefined,
        title: t(`themeModeSettings.${mode}`),
        dataTestId: `theme_mode_select_${mode}`,
        onClick: () => handleThemeModeChange(mode)
      }))}
    />
  )
}

import {Components, Theme, alpha} from '@mui/material'

import InterVar from '../../common/assets/fonts/Inter.var.woff2'

import {customThemeConstants} from './designTokens'

export const componentsOverrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: (theme) => `
      body {
        min-height: 100vh;
        height: 100%;
        background: ${
          theme.palette.mode === 'light'
            ? customThemeConstants().palette.backgroundGradient
            : theme.palette.common.black
        };
      }
      @font-face {
        font-family: 'Inter';
        font-style: oblique 0deg 10deg;
        font-weight: 100 900;
        font-display: swap;
        src: local('Inter'), url('${InterVar}') format('woff2');
      }
    }`
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true
    },
    styleOverrides: {
      root: ({theme}) => ({
        borderRadius: 8,
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:hover': {
          backgroundColor: theme.palette.grey[100]
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          '& .MuiSelect-select:focus': {
            background: 'none'
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none'
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
          '&:hover': {
            backgroundColor: theme.palette.grey[300]
          }
        }
      })
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({theme}) => ({
        backgroundColor: theme.palette.common.white,
        '&.Mui-disabled': {
          background: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiSvgIcon-root': {
            opacity: 0
          }
        }
      }),
      input: ({theme}) => ({
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          ...theme.typography.h4,
          WebkitTextFillColor: 'unset'
        }
      })
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: ({theme}) => ({
        '.MuiFilledInput-root': {
          paddingTop: theme.spacing(2.125)
        }
      })
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.text.secondary,
        '&.Mui-focused': {
          color: theme.palette.text.secondary
        }
      })
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: ({theme}) => ({
        '&.Mui-disabled .MuiSvgIcon-root ': {
          color: theme.palette.text.disabled
        }
      })
    }
  },
  MuiButton: {
    defaultProps: {
      variant: 'outlined'
    },
    styleOverrides: {
      root: {
        padding: '10px 16px'
      },
      outlinedSecondary: ({theme: {palette}}) => ({
        color: palette.mode === 'light' ? palette.secondary.dark : palette.secondary.light
      })
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({theme}) => ({
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        '&.Mui-selected.MuiToggleButton-primary': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main
        }
      })
    }
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({theme}) => ({
        border: `1px solid ${alpha(theme.palette.secondary.dark, 0.15)}`,
        '& svg': {
          marginRight: 1
        }
      }),
      grouped: ({theme}) => {
        const border = `1px solid ${alpha(theme.palette.secondary.dark, 0.15)}`
        return {
          backgroundColor: 'transparent',
          margin: 4,
          border,
          '&:not(.Mui-selected)': {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.common.white
            }
          },
          '&.Mui-disabled': {
            border: 0,
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.light,
              '& svg': {
                color: theme.palette.common.white
              }
            },
            '&:not(.Mui-selected)': {
              color: alpha(theme.palette.secondary.dark, 0.3)
            }
          },
          '&:not(:first-of-type)': {
            borderRadius: 8,
            border
          },
          '&:first-of-type': {
            borderRadius: 8
          }
        }
      }
    }
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'outlined'
    }
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'small'
    }
  },
  MuiTable: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({theme: {palette}}) => ({
        borderBottomColor: palette.mode === 'light' ? palette.grey[200] : palette.grey[700]
      }),
      sizeSmall: {
        paddingTop: 8,
        paddingBottom: 8
      },
      head: ({theme: {palette}}) => ({
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: palette.text.secondary,
        backgroundColor: palette.mode === 'light' ? palette.grey[100] : palette.grey[800],
        border: 0,
        '&:nth-of-type(1)': {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4
        },
        '&:nth-last-of-type(1)': {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4
        }
      })
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(3)
      })
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        overflow: 'visible'
      })
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        '&>:not(:first-of-type)': {
          marginLeft: theme.spacing(1.5)
        }
      })
    }
  },
  MuiTypography: {
    styleOverrides: {
      caption: ({theme}) => ({
        color: theme.palette.text.secondary
      })
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({theme}) => ({
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0
        },
        '&:before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          backgroundColor: theme.palette.grey[50]
        },
        '&:hover .MuiAccordionSummary-root': {
          backgroundColor: theme.palette.grey[100]
        }
      }),
      gutters: {
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({theme}) => ({
        position: 'relative',
        flexDirection: 'row-reverse',
        minHeight: 56,
        padding: theme.spacing(0, 3),
        '&.Mui-expanded': {
          minHeight: 56
        }
      }),
      content: {
        position: 'relative',
        width: '-webkit-fill-available',
        display: 'block'
      },
      expandIconWrapper: ({theme}) => ({
        marginRight: theme.spacing(3),
        '& svg': {
          transform: 'rotate(-90deg)'
        },
        '&.Mui-expanded': {
          '& svg': {
            transform: 'rotate(180deg)'
          }
        }
      })
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({theme}) => ({
        display: 'block',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.grey[50],
        '& > *:not(:last-of-type)': {
          paddingBottom: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.grey[200]} !important`
        },
        '& > *:not(:first-of-type)': {
          paddingTop: theme.spacing(2)
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[100]
        }
      })
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer'
      }
    }
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiTooltip: {
    defaultProps: {
      placement: 'bottom'
    },
    styleOverrides: {
      tooltip: ({theme}) => ({
        backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        boxShadow: theme.shadows[8],
        padding: theme.spacing(2)
      }),
      arrow: ({theme}) => ({
        color: theme.palette.grey[900]
      })
    }
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({theme}) => ({
        width: 60,
        padding: theme.spacing(1) // 8px
      }),
      switchBase: {
        padding: '10px',
        '&.Mui-checked': {
          transform: 'translateX(22px)',
          '& + .MuiSwitch-track': {
            opacity: 1
          }
        },
        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            opacity: 0.5
          }
        }
      },
      thumb: ({theme}) => ({
        color: theme.palette.common.white,
        width: 18,
        height: 18
      }),
      track: {
        borderRadius: '11px'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      input: () => ({
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      })
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({theme}) => ({
        position: 'absolute',
        bottom: `-${theme.spacing(2)}`
      })
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({theme: {palette}}) => ({
        backgroundColor: palette.background.paper,
        '&.Mui-selected': {
          backgroundColor: palette.mode === 'light' ? palette.grey[100] : palette.common.black
        },
        '&:hover': {
          backgroundColor: palette.mode === 'light' ? palette.grey[100] : palette.common.black,
          cursor: 'pointer'
        }
      })
    }
  }
}
